import React from 'react'
import { Link } from "react-router-dom";
import SuccessfullyCompleted from '../home/SuccessfullyCompleted';


const BusinessSetup = () => {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <section className="breadcrumb__area breadcrumb__bg" style={{ background: "url(./assets/img/bg/breadcrumb_bg.jpg)" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="breadcrumb__content mt-lg-5">

                  <br />
                  <br />
                  <h2 className="title">Business Setup</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">Business Setup</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="breadcrumb__shape">
            <img src="assets/img/images/breadcrumb_shape01.png" alt />
            <img src="assets/img/images/breadcrumb_shape02.png" alt className="rightToLeft" />
            <img src="assets/img/images/breadcrumb_shape03.png" alt />
            <img src="assets/img/images/breadcrumb_shape04.png" alt />
            <img src="assets/img/images/breadcrumb_shape05.png" alt className="alltuchtopdown" />
          </div>
        </section>

        <section className="services__details-area">
          <div className="container">
            <div className="services__details-wrap">
              <div className="row">
                <div className="col-70 order-0 order-lg-2">
                  <div className="services__details-top">
                    <div className="row gutter-24 align-items-center">
                      <div className="col-49">
                        <div className="services__details-thumb services__details-thumb-two">
                          <img src="assets/img//img/2.JPEG" alt />
                          {/* <img src="assets/img/services/services_details02.jpg" alt /> */}
                        </div>
                      </div>
                      <div className="col-51">
                        <div className="services__details-top-content">
                          <h2 className="title">Business setup</h2>
                          <p>Business setup refers to the process of establishing and organizing a new business entity. It involves everything from conceptualizing the business idea to legally forming the company, setting up operations, acquiring necessary resources, and initiating activities to begin offering goods or services.</p>
                          <div className="about__list-box about__list-box-three">
                            <ul className="list-wrap">
                              <li><i className="flaticon-arrow-button" />Business Growth</li>
                              <li><i className="flaticon-arrow-button" />100% Secure</li>
                              <li><i className="flaticon-arrow-button" />Register Your Business</li>
                              <li><i className="flaticon-arrow-button" />100% Secure</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="services__details-content">
                    <p>Define your business idea. Is it a product or service? Who are your target customers? Conduct market research to understand your audience, competition, and industry trends.</p>
                    <p>Create a detailed business plan outlining your objectives, target market, financial projections, and marketing strategy. It's like a roadmap for your business.</p>
                    <p>Register your business name and obtain the necessary licenses and permits as required by your state or country.</p>
                  </div>
                </div>
                <div className="col-30">
                  <aside className="services__sidebar">
                    <div className="sidebar__widget sidebar__widget-three">
                      <div className="sidebar__cat-list-two sidebar__cat-list-three">
                        <ul className="list-wrap">
                          <li><a to="services-details.html">Domestic & Foreign  <i className="flaticon-arrow-button" /></a></li>
                          <li><a to="services-details.html">Limited Libility Partnership Firm, One Man Company <i className="flaticon-arrow-button" /></a></li>
                          <li><a to="services-details.html">Firms <i className="flaticon-arrow-button" /></a></li>
                          <li><a to="services-details.html">Trust <i className="flaticon-arrow-button" /></a></li>
                          <li><a to="services-details.html">Society Etc <i className="flaticon-arrow-button" /></a></li>
                        </ul>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SuccessfullyCompleted />
      </div>
    </>
  )
}

export default BusinessSetup