import React from 'react'
import { Link } from "react-router-dom";


const Banner = () => {
  return (
    <>

      <section className="banner-area banner-bg" style={{background: "url(./assets/img/banner/banner_bg.jpg)"}} >

        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-content">
                <span className="sub-title">Talk to World's Best</span>
                <h2 className="title" data-aos="fade-up" data-aos-delay={200}>business Consulting All Solutions
                </h2>
                <p data-aos="fade-up" data-aos-delay={400}>We provides always our best services for our clients and always
                  try to achieve our client's trust and satisfaction.</p>
                <Link to="/contact" className="btn" data-aos="fade-up" data-aos-delay={600}>Read More</Link>
              </div>
              <div className="banner-shape">
                <img src="assets/img/banner/banner_shape01.png" alt className="rightToLeft" />
                <img src="assets/img/banner/banner_shape02.png" alt className="ribbonRotate" />
              </div>
            </div>
          </div>
          {/* <div className="banner-social">
            <h5 className="title">Follow us</h5>
            <ul className="list-wrap">
              <li><a to=""><i className="fab fa-facebook-f" /></a></li>
              <li><a to=""><i className="fab fa-twitter" /></a></li>
              <li><a to=""><i className="fab fa-instagram" /></a></li>
              <li><a to=""><i className="fab fa-pinterest-p" /></a></li>
              <li><a to=""><i className="fab fa-linkedin-in" /></a></li>
            </ul>
          </div> */}
        </div>
      </section>
    </>
  )
}

export default Banner