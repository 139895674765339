import React from "react";
import BlogComponent from "./blog/BlogComponent";

const Blogpage = () => {
  return (
    <>
      <div className="margt">
        <BlogComponent />
      </div>
    </>
  );
};

export default Blogpage;
