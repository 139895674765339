import "./App.css";
import MainLayout from "./components/MainLayout";
import {
  HashRouter,
  Routes,
  Route,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import Home from "./pages/Home.jsx";
import Contact from "./pages/contact/Contact.jsx";
import Services from "./pages/services/Services.jsx";
import About from "./pages/about/About.jsx";
import BusinessSetup from "./pages/services/BusinessSetup.jsx";
import AccountingBookKeeping from "./pages/services/AccountingBookKeeping.jsx";
import StatutoryCompliances from "./pages/services/StatutoryCompliances.jsx";
import InternalAudits from "./pages/services/InternalAudits.jsx";
import RepresentationToIncomeTax from "./pages/services/RepresentationToIncomeTax.jsx";
import PayrollProcessing from "./pages/services/PayrollProcessing.jsx";
import RbiFema from "./pages/services/RbiFema.jsx";
import RocAnnualReturn from "./pages/services/RocAnnualReturn.jsx";
import StockAudit from "./pages/services/StockAudit.jsx";
import ProjectReport from "./pages/services/ProjectReport.jsx";
import FilingOfIncome from "./pages/services/FilingOfIncome.jsx";
import AllStatutoryRegistrations from "./pages/services/AllStatutoryRegistrations.jsx";
import DealAdvisory from "./pages/services/DealAdvisory.jsx";
import FinancialServices from "./pages/services/FinancialServices.jsx";
import ProjectManagement from "./pages/services/ProjectManagement.jsx";
import SecurityOfProperty from "./pages/services/SecurityOfProperty.jsx";
import RealEstateServices from "./pages/services/RealEstateServices.jsx";
import { useEffect } from "react";
import B2BServices from "./pages/services/B2BServices.jsx";
import BlogDetails from "./pages/BlogDetails.jsx";
import Blogpage from "./pages/Blogpage.jsx";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/businessSetup" element={<BusinessSetup />} />
            <Route
              path="/AccountingBookKeeping"
              element={<AccountingBookKeeping />}
            />
            <Route
              path="/StatutoryCompliances"
              element={<StatutoryCompliances />}
            />
            <Route path="/InternalAudits" element={<InternalAudits />} />
            <Route
              path="/RepresentationToIncomeTax"
              element={<RepresentationToIncomeTax />}
            />
            <Route path="/PayrollProcessing" element={<PayrollProcessing />} />
            <Route path="/RocAnnualReturn" element={<RocAnnualReturn />} />
            <Route path="/RbiFema" element={<RbiFema />} />
            <Route path="/StockAudit" element={<StockAudit />} />
            <Route path="/ProjectReport" element={<ProjectReport />} />
            <Route path="/FilingOfIncome" element={<FilingOfIncome />} />
            <Route
              path="/AllStatutoryRegistrations"
              element={<AllStatutoryRegistrations />}
            />
            <Route path="/DealAdvisory" element={<DealAdvisory />} />
            <Route path="/FinancialServices" element={<FinancialServices />} />
            <Route path="/ProjectManagement" element={<ProjectManagement />} />
            <Route
              path="/SecurityOfProperty"
              element={<SecurityOfProperty />}
            />
            <Route
              path="/RealEstateServices"
              element={<RealEstateServices />}
            />
            <Route path="/B2BServices" element={<B2BServices />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/blog/:slugUrl" element={<BlogDetails />} />
            <Route path="/blog" element={<Blogpage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
