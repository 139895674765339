import React from 'react'
import { Link } from "react-router-dom";
import SuccessfullyCompleted from '../home/SuccessfullyCompleted';

const B2BServices = () => {
    return (
        <>

            <div style={{ overflow: "hidden" }}>
                <section className="breadcrumb__area breadcrumb__bg" style={{ background: "url(./assets/img/bg/breadcrumb_bg.jpg)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="breadcrumb__content mt-lg-5">

                                    <br />
                                    <br />
                                    <h2 className="title">B2B Services</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">B2B Services</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb__shape">
                        <img src="assets/img/images/breadcrumb_shape01.png" alt />
                        <img src="assets/img/images/breadcrumb_shape02.png" alt className="rightToLeft" />
                        <img src="assets/img/images/breadcrumb_shape03.png" alt />
                        <img src="assets/img/images/breadcrumb_shape04.png" alt />
                        <img src="assets/img/images/breadcrumb_shape05.png" alt className="alltuchtopdown" />
                    </div>
                </section>

                <section className="services__details-area">
                    <div className="container">
                        <div className="services__details-wrap">
                            <div className="row">
                                <div className="col-70 order-0 order-lg-2">
                                    <div className="services__details-top">
                                        <div className="row gutter-24 align-items-center">
                                            <div className="col-49">
                                                <div className="services__details-thumb services__details-thumb-two">
                                                    <img src="assets/img/services/services_details02.jpg" alt />
                                                </div>
                                            </div>
                                            <div className="col-51">
                                                <div className="services__details-top-content">
                                                    <h2 className="title">B2B Services</h2>
                                                    <p>Business-to-Business (B2B) services form a critical component of the global economy, enabling companies to collaborate, trade, and support one another in various capacities. Unlike Business-to-Consumer (B2C) transactions that involve businesses selling products or services directly to end consumers, B2B services focus on transactions and interactions between businesses themselves. </p>
                                                    <div className="about__list-box about__list-box-three">
                                                        <ul className="list-wrap">
                                                            <li><i className="flaticon-arrow-button" />Business Growth</li>
                                                            <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                            <li><i className="flaticon-arrow-button" />Register Your Business</li>
                                                            <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="services__details-content">
                                        <p>
                                            B2B services involve transactions between two or more businesses. This can include the exchange of goods, services, information, or a combination of these.
                                        </p>
                                        <p>B2B transactions often involve more complex decision-making processes than B2C transactions. Purchases are typically made by organizational decision-makers who consider factors such as cost, functionality, scalability, and long-term value.</p>
                                        <p> Building and maintaining strong business relationships is crucial in the B2B space. Long-term partnerships and trust are often essential for successful collaboration.</p>
                                        <p>
                                            B2B services often cater to the specific needs of businesses. Customization and scalability are important features, allowing companies to adapt to evolving business requirements.
                                        </p>
                                        <p>
                                            B2B services are integral to supply chain management. Companies rely on B2B relationships to source raw materials, components, and finished products from suppliers.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-30">
                                    <aside className="services__sidebar">
                                        <div className="sidebar__widget sidebar__widget-three">
                                            <div className="sidebar__cat-list-two sidebar__cat-list-three">
                                                <ul className="list-wrap">
                                                    <li><a to="services-details.html">Domestic & Foreign  <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Limited Libility Partnership Firm, One Man Company <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Firms <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Trust <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Society Etc <i className="flaticon-arrow-button" /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SuccessfullyCompleted />
            </div>
        </>
    )
}

export default B2BServices