import React from "react";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <section
          className="breadcrumb__area breadcrumb__bg"
          style={{ background: "url(./assets/img/bg/breadcrumb_bg.jpg)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="breadcrumb__content mt-lg-5">
                  <br />
                  <br />

                  <h2 className="title">All Services</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Services
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="breadcrumb__shape">
            <img src="assets/img/images/breadcrumb_shape01.png" alt />
            <img
              src="assets/img/images/breadcrumb_shape02.png"
              alt
              className="rightToLeft"
            />
            <img src="assets/img/images/breadcrumb_shape03.png" alt />
            <img src="assets/img/images/breadcrumb_shape04.png" alt />
            <img
              src="assets/img/images/breadcrumb_shape05.png"
              alt
              className="alltuchtopdown"
            />
          </div>
        </section>

        <section
          className="services__area-five services__bg-five"
          data-background="assets/img/bg/inner_services_bg02.jpg"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="section-title text-center mb-40">
                  <span className="sub-title">WHAT WE OFFER</span>
                  <h2 className="title">
                    We Offer An Effective Wide Area Business solutions
                  </h2>
                </div>
              </div>
            </div>
            <div className="services-item-wrap">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      {/* <Link to="/" className="shine-animate"><img src="assets/img/services/services_img01.jpg" alt /></Link> */}
                      <Link to="/" className="shine-animate">
                        <img src="assets/img/img/2.JPEG" alt />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-profit" />
                      </div>
                      <h4 className="title">
                        <Link to="/">Business Setup</Link>
                      </h4>
                      <p>
                        {" "}
                        Develop a detailed business plan outlining your
                        objectives, target market, marketing strategy
                        projections.
                      </p>
                      <br />
                      <Link to="/businessSetup" className="btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link to="/" className="shine-animate">
                        <img src="assets/img/services/services_img02.jpg" alt />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-investment-1" />
                      </div>
                      <h4 className="title">
                        <Link to="/AccountingBookKeeping">
                          Accounting % Book Keeping
                        </Link>
                      </h4>
                      <p>
                        {" "}
                        on the other hand, is a broader field that includes
                        bookkeeping but goes beyond it.
                      </p>
                      <br />

                      <Link to="/AccountingBookKeeping" className="btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link to="/" className="shine-animate">
                        <img src="assets/img/img/4.JPEG" alt />
                      </Link>
                      {/* <Link to="/" className="shine-animate"><img src="assets/img/services/services_img03.jpg" alt /></Link> */}
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-pie-chart" />
                      </div>
                      <h4 className="title">
                        <Link to="/StatutoryCompliances">
                          Statutory Compliances
                        </Link>
                      </h4>
                      <p>
                        Adherence to labor laws regarding minimum wages, working
                        hours, employee benefits, and workplace safety
                        standards.
                      </p>
                      <Link to="/StatutoryCompliances" className="btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link to="/" className="shine-animate">
                        <img src="assets/img/services/services_img04.jpg" alt />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-light-bulb" />
                      </div>
                      <h4 className="title">
                        <Link to="/InternalAudits">Internal Audits</Link>
                      </h4>
                      <p>
                        Evaluating the effectiveness of internal controls and
                        processes in place to ensure they are adequate in
                        managing risks and achieving organizational goals.
                      </p>
                      <Link to="/InternalAudits" className="btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link to="/" className="shine-animate">
                        <img src="assets/img/services/services_img05.jpg" alt />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-investment" />
                      </div>
                      <h4 className="title">
                        <Link to="/RepresentationToIncomeTax">
                          Representation To Income Tax And Other Government
                          Bodies
                        </Link>
                      </h4>
                      <p>
                        Taxpayers often engage tax consultants, accountants, or
                        tax attorneys to represent them before tax authorities.
                      </p>
                      <Link to="/RepresentationToIncomeTax" className="btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link to="/" className="shine-animate">
                        <img src="assets/img/services/services_img06.jpg" alt />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-piggy-bank" />
                      </div>
                      <h4 className="title">
                        <Link to="/PayrollProcessing">
                          Payroll Processing And HR Assistance
                        </Link>
                      </h4>
                      <p>
                        HR assistance often involves a range of services related
                        to managing employees within a company.
                      </p>
                      <br />

                      <Link to="/PayrollProcessing" className="btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link to="/" className="shine-animate">
                        <img src="assets/img/services/services_img07.jpg" alt />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-financial-profit" />
                      </div>
                      <h4 className="title">
                        <Link to="/RbiFema">
                          RBI & FEMA Compliances Assistance
                        </Link>
                      </h4>
                      <p>
                        RBI regulates the country's monetary policy, issues
                        currency, manages foreign exchange, and supervises banks
                        and financial institutions.
                      </p>
                      <Link to="/RbiFema" className="btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link to="/" className="shine-animate">
                        <img src="assets/img/services/services_img08.jpg" alt />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-budget" />
                      </div>
                      <h4 className="title">
                        <Link to="/RocAnnualReturn">
                          ROC Annual Return Filing & Related Secretarial Work
                        </Link>
                      </h4>
                      <p>
                        It is a statutory requirement for companies registered
                        under the Companies Act in many jurisdictions.
                      </p>
                      <Link to="/RocAnnualReturn" className="btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="services__area-four services__bg-four"
          style={{ backgroundColor: "#ecf6fa" }}
        >
          <div className="container">
            <div className="row justify-content-center gutter-24">
              <div className="col-lg-4 col-md-6">
                <div className="services__item-three">
                  <div className="services__item-top">
                    <div className="services__icon-three">
                      <i className="flaticon-profit" />
                    </div>
                    <div className="services__item-top-title">
                      <h2 className="title">
                        <Link to="/StockAudit">Stock Audit</Link>
                      </h2>
                    </div>
                  </div>
                  <div className="services__content-three">
                    <p>
                      It involves counting, inspecting, and validating the
                      quantity and quality of goods or materials held by a
                      business at a particular point in time.
                    </p>
                    <Link to="/StockAudit" className="btn btn-two">
                      Read More
                    </Link>
                  </div>
                  <br />
                  <br />
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="services__item-three">
                  <div className="services__item-top">
                    <div className="services__icon-three">
                      <i className="flaticon-target" />
                    </div>
                    <div className="services__top-title">
                      <h2 className="title">
                        <Link to="/B2BServices">B2B Services</Link>
                      </h2>
                    </div>
                  </div>
                  <div className="services__content-three">
                    {/* <p>Mauris utenim sitamet lacus ornar eary ullamcperson Praesent plaacera treat neque eu
                                            purus rhoncu</p> */}
                    <p>
                      Business-to-Business (B2B) services form a critical
                      component of the global economy, enabling companies to
                      collaborate, trade, and support one another in various
                      capacities.
                    </p>
                    <br />

                    <Link to="/B2BServices" className="btn btn-two">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services__item-three">
                  <div className="services__item-top">
                    <div className="services__icon-three">
                      <i className="flaticon-financial-profit" />
                    </div>
                    <div className="services__top-title">
                      <h2 className="title">
                        <Link to="/ProjectReport">
                          Project Report / CMA For Bank Loan
                        </Link>
                      </h2>
                    </div>
                  </div>
                  <div className="services__content-three">
                    <p>
                      A Project Report or Credit Monitoring Arrangement (CMA) is
                      a crucial document required by banks or financial
                      institutions when a borrower applies for a loan.{" "}
                    </p>
                    <Link to="/ProjectReport" className="btn btn-two">
                      Read More
                    </Link>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services__item-three">
                  <div className="services__item-top">
                    <div className="services__icon-three">
                      <i className="flaticon-profit" />
                    </div>
                    <div className="services__item-top-title">
                      <h2 className="title">
                        <Link to="/FilingOfIncome">
                          Filing Of Income Tax Reurns For Individuals
                        </Link>
                      </h2>
                    </div>
                  </div>
                  <div className="services__content-three">
                    <br />
                    <p>
                      Filing income tax returns for individuals involves
                      reporting your income, deductions, and other relevant
                      financial information to the government.
                    </p>
                    <Link to="/FilingOfIncome" className="btn btn-two">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services__item-three">
                  <div className="services__item-top">
                    <div className="services__icon-three">
                      <i className="flaticon-target" />
                    </div>
                    <div className="services__top-title">
                      <h2 className="title">
                        <Link to="/AllStatutoryRegistrations">
                          All Statutory Registrations
                        </Link>
                      </h2>
                    </div>
                  </div>
                  <div className="services__content-three">
                    <p>
                      These registrations vary depending on the country,
                      industry, and nature of the business.
                    </p>
                    <br />
                    <br />
                    <Link
                      to="/AllStatutoryRegistrations"
                      className="btn btn-two"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services__item-three">
                  <div className="services__item-top">
                    <div className="services__icon-three">
                      <i className="flaticon-financial-profit" />
                    </div>
                    <div className="services__top-title">
                      <h2 className="title">
                        <Link to="/DealAdvisory">Deal Advisory</Link>
                      </h2>
                    </div>
                  </div>
                  <div className="services__content-three">
                    <p>
                      Deal advisory refers to a range of professional services
                      provided by advisory firms to assist businesses and
                      investors in various aspects of mergers, acquisitions,
                      divestitures, and other transactions.
                    </p>
                    <Link to="/DealAdvisory" className="btn btn-two">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services__shape-wrap-two">
            <img
              src="assets/img/services/inner_services_shape01.png"
              alt
              data-aos="fade-right"
              data-aos-delay={400}
            />
            <img
              src="assets/img/services/inner_services_shape02.png"
              alt
              data-aos="fade-left"
              data-aos-delay={400}
            />
          </div>
        </section>

        <section
          className="services__area-five services__bg-five"
          data-background="assets/img/bg/inner_services_bg02.jpg"
        >
          <div className="container">
            <div className="services-item-wrap">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link to="/" className="shine-animate">
                        <img src="assets/img/services/services_img05.jpg" alt />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-investment" />
                      </div>
                      <h4 className="title">
                        <Link to="/FinancialServices">Financial Services</Link>
                      </h4>
                      <p>
                        Financial services encompass a broad range of economic
                        services offered by financial institutions, encompassing
                        banking, investment, insurance, and various other
                        services related to managing money.
                      </p>
                      <Link to="/FinancialServices" className="btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link to="/" className="shine-animate">
                        <img src="assets/img/services/services_img06.jpg" alt />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-piggy-bank" />
                      </div>
                      <h4 className="title">
                        <Link to="/ProjectManagement">Project Management</Link>
                      </h4>
                      <p>
                        Project management is the process of planning,
                        organizing, and overseeing the execution of a project,
                        from its initiation to its completion.
                      </p>
                      <br />
                      <br />
                      <Link to="/ProjectManagement" className="btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link to="/" className="shine-animate">
                        <img src="assets/img/services/services_img07.jpg" alt />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-financial-profit" />
                      </div>
                      <h4 className="title">
                        <Link to="/SecurityOfProperty">
                          Security Of Property Belonging To Absentee Land Lord
                        </Link>
                      </h4>
                      <p>
                        This situation commonly arises when a property owner
                        lives far away from their property or delegates the
                        management to a third party.
                      </p>
                      <Link to="/SecurityOfProperty" className="btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="services-item shine-animate-item">
                    <div className="services-thumb">
                      <Link to="/" className="shine-animate">
                        <img src="assets/img/services/services_img08.jpg" alt />
                      </Link>
                    </div>
                    <div className="services-content">
                      <div className="icon">
                        <i className="flaticon-budget" />
                      </div>
                      <h4 className="title">
                        <Link to="/RealEstateServices">
                          Real Estate Services
                        </Link>
                      </h4>
                      <p>
                        Real estate services encompass a broad range of
                        professional services related to property transactions,
                        management, and development.
                      </p>
                      <br />
                      <Link to="/RealEstateServices" className="btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Services;
