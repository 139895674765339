import React from 'react'

const WhyBest = () => {
    return (
        <>
        <section className="choose-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 order-0 order-lg-2">
                        <div className="choose-img-wrap">
                            <img src="assets/img/images/choose_img01.jpg" alt />
                            <img src="assets/img/images/choose_img02.jpg" alt data-parallax="{&quot;x&quot; : 50 }" />
                            <img src="assets/img/images/choose_img_shape.png" alt className="alltuchtopdown" />
                        </div>
                    </div>  
                    <div className="col-lg-6">
                        <div className="choose-content">
                            <div className="section-title white-title mb-30 tg-heading-subheading animation-style3">
                                <span className="sub-title">Why We Are The Best</span>
                                <h2 className="title tg-element-title">We Offer Business Insight <br /> World Class Consulting
                                </h2>
                            </div>
                            <p>We successfully cope with tasks of varying complexity provide area longerty guarantees
                                and regularly master new Practice Following gies heur portfolio includes dozen.</p>
                            <div className="choose-list">
                                <ul className="list-wrap">
                                    <li>
                                        <div className="icon">
                                            <i className="flaticon-investment" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Business Solutions</h4>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="flaticon-investment-1" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Financial Services</h4>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="choose-shape-wrap">
                <img src="assets/img/images/choose_shape01.png" alt data-aos="fade-right" data-aos-delay={400} />
                <img src="assets/img/images/choose_shape02.png" alt data-aos="fade-left" data-aos-delay={400} />
            </div>
        </section>
        </>
    )
}

export default WhyBest