import React from 'react'
import { Link } from "react-router-dom";
import WhatsappIcon from '../../pages/WhatsappIcon';


const Footer = () => {
    return (
        <>
        <WhatsappIcon />
            <footer>
                <div className="footer-area mt-lg-5">
                    <div className="footer-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 col-md-6">
                                    <div className="footer-widget">
                                        <div className="fw-logo mb-25" style={{}}>
                                            <a to="index.html"><img src="assets/img/img/logo.jpg" alt /></a>
                                        </div>
                                        <div className="footer-content">
                                            <p>To fully support customers with a variety of requirements we have created five clearly defined service divisions.
                                            </p>
                                            <div className="footer-social">
                                                <ul className="list-wrap">
                                                    <li><a to=""><i className="fab fa-facebook-f" /></a></li>
                                                    <li><a to=""><i className="fab fa-twitter" /></a></li>
                                                    <li><a to=""><i className="fab fa-instagram" /></a></li>
                                                    <li><a to=""><i className="fab fa-pinterest-p" /></a></li>
                                                    <li><a to=""><i className="fab fa-youtube" /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-4 col-md-6 col-sm-6">
                                    <div className="footer-widget">
                                        <h4 className="fw-title">Information</h4>
                                        <div className="footer-info-list">
                                            <ul className="list-wrap">
                                                <li>
                                                    <div className="icon">
                                                        <i className="flaticon-phone-call" />
                                                    </div>
                                                    <div className="content">
                                                        <a to="">+91-9848181424
                                                        <br/> +91 9959037926</a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="flaticon-envelope" />
                                                    </div>
                                                    <div className="content">
                                                        <a to="/"><span className="__cf_email_">care@surakconsultants.com</span></a><br/>
                                                        <a to="/"><span className="__cf_email_"> accounts@surakconsultants.com</span></a>
                                                        <a to="/"><span className="__cf_email_"> surak14051962@gmail.com</span></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="flaticon-pin" />
                                                    </div>
                                                    <div className="content">
                                                        {/* <p>H.No:2-52/A, Ground Floor, Chandanagar, Hyderabad.</p> */}
                                                        <p>D.No.2-52/A, Ground Floor, Beside Lane To Bajaj Electronics,Chanadanagar,Hyderabad-500050</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <div className="footer-widget">
                                        <h4 className="fw-title">Top Links</h4>
                                        <div className="footer-link-list">
                                            <ul className="list-wrap">
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/about">About Us</Link></li>
                                                <li><Link to="/services">Services</Link></li>
                                                <li><Link to="/contact">Contact</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xl-3 col-lg-4 col-md-6">
                                    <div className="footer-widget">
                                        <h4 className="fw-title">Recent Posts</h4>
                                        <div className="footer-instagram">
                                            <ul className="list-wrap">
                                                <li><a to=""><img src="assets/img/images/footer_insta01.jpg" alt /></a></li>
                                                <li><a to=""><img src="assets/img/images/footer_insta02.jpg" alt /></a></li>
                                                <li><a to=""><img src="assets/img/images/footer_insta03.jpg" alt /></a></li>
                                                <li><a to=""><img src="assets/img/images/footer_insta04.jpg" alt /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="container">
                            <div className="row align-items-center">
                                {/* <div className="col-lg-7 order-0 order-lg-2">
                                    <div className="footer-newsletter">
                                        <h4 className="title">Newsletter SignUp!</h4>
                                        <form action="#">
                                            <input type="text" placeholder="e-mail Type . . ." />
                                            <button className="btn btn-two" type="">Subscribe</button>
                                        </form>
                                    </div>
                                </div> */}
                                <div className="col-lg-5">
                                    <div className="copyright-text">
                                        <p>© 2023 <a to="index.html">Surak Consultants Pvt. Ltd.</a> | Design by <a href='https://dexterous.in/' target='_blank'>Dexterous Technology</a></p>
                                        {/* <a to="contact.html">Support Terms &amp; Conditions Privacy Policy.</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-shape">
                        <img src="assets/img/images/footer_shape01.png" alt data-aos="fade-right" data-aos-delay={400} />
                        <img src="assets/img/images/footer_shape02.png" alt data-aos="fade-left" data-aos-delay={400} />
                        <img src="assets/img/images/footer_shape03.png" alt data-parallax="{&quot;x&quot; : 100 , &quot;y&quot; : -100 }" />
                    </div>
                </div>
            </footer>


        </>
    )
}

export default Footer