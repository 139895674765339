import React from 'react'

const WhatsappIcon = () => {
  return (
    <div>
        <div className="whatsapp_float">
        <a href="https://wa.me/919848181424" target="_blank">
          <img
            src="assets/img/img/WhatsappLogo.png"
            width="50px"
            className="whatsapp_float_btn"
          />
        </a>
      </div>
    </div>
  )
}

export default WhatsappIcon