import React from 'react'
import { Link } from "react-router-dom";


const PayrollProcessing = () => {
    return (
        <>
<div className='' style={{overflow:"hidden"}}>
            <section className="breadcrumb__area breadcrumb__bg" style={{background: "url(./assets/img/bg/breadcrumb_bg.jpg)"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="breadcrumb__content mt-lg-5">
                            <br/>
                            <br/>
                                <h2 className="title">Payroll Processing</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Payroll Processing</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb__shape">
                    <img src="assets/img/images/breadcrumb_shape01.png" alt />
                    <img src="assets/img/images/breadcrumb_shape02.png" alt className="rightToLeft" />
                    <img src="assets/img/images/breadcrumb_shape03.png" alt />
                    <img src="assets/img/images/breadcrumb_shape04.png" alt />
                    <img src="assets/img/images/breadcrumb_shape05.png" alt className="alltuchtopdown" />
                </div>
            </section>

            <section className="services__details-area">
                <div className="container">
                    <div className="services__details-wrap">
                        <div className="row">
                            <div className="col-70 order-0 order-lg-2">
                                <div className="services__details-top">
                                    <div className="row gutter-24 align-items-center">
                                        <div className="col-49">
                                            <div className="services__details-thumb services__details-thumb-two">
                                                <img src="assets/img/services/services_details02.jpg" alt />
                                            </div>
                                        </div>
                                        <div className="col-51">
                                            <div className="services__details-top-content">
                                                <h2 className="title">Payroll processing</h2>
                                                <p>Payroll processing involves managing the financial aspects of employee compensation, such as calculating wages, withholding taxes, deducting benefits, and issuing payments to employees. It ensures that employees are paid accurately and on time while adhering to legal and tax regulations.
                                                </p>
                                                <div className="about__list-box about__list-box-three">
                                                    <ul className="list-wrap">
                                                        <li><i className="flaticon-arrow-button" />Business Growth</li>
                                                        <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                        <li><i className="flaticon-arrow-button" />Register Your Business</li>
                                                        <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="services__details-content">
                                   <p>HR assistance, on the other hand, involves a broader range of support related to human resources functions. This can include recruitment, onboarding, employee relations, policy development, benefits administration, performance management, training, and compliance with employment laws and regulations.</p>
                                   <p>Combining payroll processing and HR assistance often means a service or department that handles not only the financial aspects of employee compensation (payroll) but also provides support and guidance on various HR-related matters to ensure a smooth and compliant working environment. This integrated approach can streamline administrative tasks, ensure accuracy in payment and compliance, and support overall employee management within an organization.</p>
                                </div>
                            </div>
                            <div className="col-30">
                                <aside className="services__sidebar">
                                    <div className="sidebar__widget sidebar__widget-three">
                                        <div className="sidebar__cat-list-two sidebar__cat-list-three">
                                            <ul className="list-wrap">
                                                <li><a to="services-details.html">Business Setup  <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Accounting % Book Keeping <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Statutory Compliances <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Internal Audits <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Financial Services <i className="flaticon-arrow-button" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        </>
    )
}

export default PayrollProcessing