import React, { useState } from 'react'
import SuccessfullyCompleted from '../home/SuccessfullyCompleted'
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";



const Contact = () => {
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [mail, setmail] = useState("");
    const [massages, setMassages] = useState("");

    const nameChange = (e) => {
        const value = e.target.value;
        setName(value);
    };
    const mobileChange = (e) => {
        const value = e.target.value;
        setMobile(value);
    };
    const mailChange = (e) => {
        const value = e.target.value;
        setmail(value);
    };
    const massagesChange = (e) => {
        const value = e.target.value;
        setMassages(value);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_f1vxx1h",
                "template_wr4j95q",
                "#myForm",
                "xJ--wcX2apXQX22NV"
            )
            .then(
                function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                    if (response.text === "OK") {
                        setName("");
                        setMobile("");
                        setmail("");
                        setMassages("");
                    }
                },
                function (error) {
                    console.log("FAILED...", error);
                }
            );
    };
    return (
        <>
            <div style={{ overflow: "hidden" }}>
                <section className="breadcrumb__area breadcrumb__bg breadcrumb_bg1" style={{ background: "url(./assets/img/bg/breadcrumb_bg.jpg)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="breadcrumb__content">
                                    <br />
                                    <br />
                                    <h2 className="title mt-lg-5">Contact Us</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Contact</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb__shape">
                        <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
                        <img src="assets/img/images/breadcrumb_shape02.png" alt="" className="rightToLeft" />
                        <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
                        <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
                        <img src="assets/img/images/breadcrumb_shape05.png" alt="" className="alltuchtopdown" />
                    </div>
                </section>

                <section className="contact__area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="contact__content">
                                    <div className="section-title mb-35">
                                        <h2 className="title">How can we help you?</h2>
                                        {/* <p>Complete our online form below to send an email to our Customer Service team. This mailbox is managed between 10:00AM & 06.00PM Monday to Friday and we aim to respond to your email as soon as possible, usually within 24 hours of receipt on a weekday. Please note calls may be recorded for training and quality purposes.
                                        </p> */}
                                    </div>
                                    <div className="contact__info">
                                        <ul className="list-wrap">
                                            <li>
                                                <div className="icon">
                                                    <i className="flaticon-pin" />
                                                </div>
                                                <div className="content">
                                                    <h4 className="title">Address</h4>
                                                    <p> D.No.2-52/A, Ground Floor, Beside Lane To Bajaj Electronics,Chanadanagar,Hyderabad-500050
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <i className="flaticon-phone-call" />
                                                </div>
                                                <div className="content">
                                                    <h4 className="title">Phone</h4>
                                                    <a to="">+91-9848181424</a><br></br>
                                                    <a to="">+91-9959037926</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <i className="flaticon-mail" />
                                                </div>
                                                <div className="content">
                                                    <h4 className="title">E-mail</h4>
                                                    <a to=""><span className="__cf_email__">care@surakconsultants.com</span></a>
                                                    <br />
                                                    <a to=""><span className="__cf_email__">accounts@surakconsultants.com</span></a>
                                                    <br />
                                                    <a to=""><span className="__cf_email__">surak14051962@gmail.com</span></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="contact__form-wrap">
                                    <h2 className="title">Give Us a Message</h2>
                                    <form onSubmit={sendEmail} id="myForm">


                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-grp">
                                                    <input type="text" name="from_name" placeholder="Name"
                                                        onChange={(e) => nameChange(e)}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-grp">
                                                    <input type="email" name="user_email" placeholder="Email"
                                                        onChange={(e) => mailChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-grp">
                                                    <input type="number" name="user_mobile" placeholder="Phone"
                                                        onChange={(e) => mobileChange(e)}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-grp">
                                            <textarea name="message" placeholder="Message" defaultValue={""}
                                                onChange={(e) => massagesChange(e)}
                                            />
                                        </div>
                                        <div className="form-grp checkbox-grp">
                                            <input type="checkbox" name="checkbox" id="checkbox" />
                                            <label htmlFor="checkbox">Save my name, email, and website in this browser for the next
                                                time I comment.</label>
                                        </div>
                                        <a href="">
                                            <button type="" className="btn">Submit</button>
                                        </a>
                                    </form>
                                    <p className="ajax-response mb-0" />
                                </div>
                            </div>

                            {/* <div className="col-lg-6 col-md-9 col-sm-10">
                                <div className="about__img-wrap-four">
                                    <img src="assets/img/images/contact-banner_o.png" alt />
                                    
                                    <div className="shape">
                                        <img src="assets/img/images/inner_about_shape.jpg" alt className="alltuchtopdown" />
                                    </div>
                                </div>
                            </div> */}

                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <SuccessfullyCompleted />

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="contact-map">
                                    <div style={{ textDecoration: 'none', overflow: 'hidden', maxWidth: '100%', width: 1300, height: 500 }}><div id="google-maps-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=Ground+Floor,+Beside+Lane+To+Bajaj+Electronics,Chanadanagar,Hyderabad&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="google-maps-html" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="get-data-for-map">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#google-maps-canvas img{max-height:none;max-width:none!important;background:none!important;}" }} /></div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default Contact