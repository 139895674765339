import React from 'react'
import { Link } from "react-router-dom";


const AccountingBookKeeping = () => {
    return (
        <>
            <div style={{ overflow: "hidden" }}>
                <section className="breadcrumb__area breadcrumb__bg" style={{ background: "url(./assets/img/bg/breadcrumb_bg.jpg)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="breadcrumb__content mt-lg-5">
                                    <h2 className="title">Accounting % Book Keeping</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Accounting % Book Keeping</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb__shape">
                        <img src="assets/img/images/breadcrumb_shape01.png" alt />
                        <img src="assets/img/images/breadcrumb_shape02.png" alt className="rightToLeft" />
                        <img src="assets/img/images/breadcrumb_shape03.png" alt />
                        <img src="assets/img/images/breadcrumb_shape04.png" alt />
                        <img src="assets/img/images/breadcrumb_shape05.png" alt className="alltuchtopdown" />
                    </div>
                </section>

                <section className="services__details-area">
                    <div className="container">
                        <div className="services__details-wrap">
                            <div className="row">
                                <div className="col-70 order-0 order-lg-2">
                                    <div className="services__details-top">
                                        <div className="row gutter-24 align-items-center">
                                            <div className="col-49">
                                                <div className="services__details-thumb services__details-thumb-two">
                                                    <img src="assets/img/services/services_details02.jpg" alt />
                                                </div>
                                            </div>
                                            <div className="col-51">
                                                <div className="services__details-top-content">
                                                    <h2 className="title">Accounting and book keeping</h2>
                                                    <p>Accounting and book keeping are closely related but have distinct roles in managing financial information for businesses.
                                                    </p>
                                                    <div className="about__list-box about__list-box-three">
                                                        <ul className="list-wrap">
                                                            <li><i className="flaticon-arrow-button" />Business Growth</li>
                                                            <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                            <li><i className="flaticon-arrow-button" />Register Your Business</li>
                                                            <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="services__details-content">
                                        <p>Bookkeeping involves the systematic recording, storing, and organizing of financial transactions of a company. It focuses on the day-to-day process of recording financial data such as sales, purchases, receipts, and payments. Bookkeepers maintain ledgers, reconcile accounts, and ensure accuracy in financial records. They generate basic financial reports like income statements and balance sheets.</p>
                                        <p>Accounting, on the other hand, encompasses a broader scope. It involves interpreting, classifying, analyzing, summarizing, and reporting financial data. Accountants use the information provided by bookkeepers to create financial statements, perform financial analysis, offer insights into business performance, provide tax advice, and make strategic recommendations. Accounting involves more complex tasks like financial forecasting, budgeting, and making decisions based on financial data.</p>
                                        <p>In summary, bookkeeping is primarily concerned with the accurate recording of financial transactions, while accounting involves interpreting, analyzing, and using that financial information for decision-making and strategic planning.</p>
                                    </div>
                                </div>
                                <div className="col-30">
                                    <aside className="services__sidebar">
                                        <div className="sidebar__widget sidebar__widget-three">
                                            <div className="sidebar__cat-list-two sidebar__cat-list-three">
                                                <ul className="list-wrap">
                                                    <li><a to="services-details.html">Business Setup  <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Accounting % Book Keeping <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Statutory Compliances <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Internal Audits <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Financial Services <i className="flaticon-arrow-button" /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default AccountingBookKeeping