import React from 'react'
import { Link } from "react-router-dom";


const StatutoryCompliances = () => {
  return (
    <>

<div className='' style={{overflow:"hidden"}}> 
         <section className="breadcrumb__area breadcrumb__bg" style={{background: "url(./assets/img/bg/breadcrumb_bg.jpg)"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="breadcrumb__content mt-lg-5">
                            <br/>
                            <br/>
                                <h2 className="title">Accounting % Book Keeping</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Accounting % Book Keeping</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb__shape">
                    <img src="assets/img/images/breadcrumb_shape01.png" alt />
                    <img src="assets/img/images/breadcrumb_shape02.png" alt className="rightToLeft" />
                    <img src="assets/img/images/breadcrumb_shape03.png" alt />
                    <img src="assets/img/images/breadcrumb_shape04.png" alt />
                    <img src="assets/img/images/breadcrumb_shape05.png" alt className="alltuchtopdown" />
                </div>
            </section>

            <section className="services__details-area">
                <div className="container">
                    <div className="services__details-wrap">
                        <div className="row">
                            <div className="col-70 order-0 order-lg-2">
                                <div className="services__details-top">
                                    <div className="row gutter-24 align-items-center">
                                        <div className="col-49">
                                            <div className="services__details-thumb services__details-thumb-two">
                                                <img src="assets/img/services/services_details02.jpg" alt />
                                            </div>
                                        </div>
                                        <div className="col-51">
                                            <div className="services__details-top-content">
                                                <h2 className="title">Statutory compliance</h2>
                                               
                                                <div className="about__list-box about__list-box-three">
                                                    <ul className="list-wrap">
                                                        <li><i className="flaticon-arrow-button" />Business Growth</li>
                                                        <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                        <li><i className="flaticon-arrow-button" />Register Your Business</li>
                                                        <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="services__details-content">
                                    <p>Statutory compliance refers to the legal framework within which an organization must operate, ensuring that it complies with all relevant laws, regulations, and statutory requirements set forth by the government or governing bodies. These regulations cover various aspects such as labor laws, taxation, environmental regulations, health and safety standards, industrial regulations, and more.</p>
                                    <p>For a business, adhering to statutory compliances involves fulfilling obligations, submitting necessary documents, maintaining records, paying taxes, and following specific protocols to ensure legal operation.</p>
                                    <p>Non-compliance can result in penalties, fines, legal actions, or even the closure of the business. Hence, companies allocate resources to ensure they meet these statutory obligations to operate within the legal boundaries set by the government.</p>
                                </div>
                            </div>
                            <div className="col-30">
                                <aside className="services__sidebar">
                                    <div className="sidebar__widget sidebar__widget-three">
                                        <div className="sidebar__cat-list-two sidebar__cat-list-three">
                                            <ul className="list-wrap">
                                                <li><a to="services-details.html">Business Setup  <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Accounting % Book Keeping <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="/StatutoryCompliances">Statutory Compliances <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Internal Audits <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Financial Services <i className="flaticon-arrow-button" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
    </>
  )
}

export default StatutoryCompliances