import React from "react";
import Banner from "./home/Banner";
import WhyBest from "./home/WhyBest";
import HomeServices from "./home/HomeServices";
import SuccessfullyCompleted from "./home/SuccessfullyCompleted";
import BestExperience from "./home/BestExperience";
import Testimonial from "./home/Testimonial";
import BlogComponent from "./blog/BlogComponent";

const Home = () => {
  return (
    <>
      <Banner />
      <WhyBest />
      <HomeServices />
      <SuccessfullyCompleted />
      <Testimonial />
      <BestExperience />
      <BlogComponent />
    </>
  );
};

export default Home;
