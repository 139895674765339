import React from 'react'
import { Link } from "react-router-dom";
import SuccessfullyCompleted from '../home/SuccessfullyCompleted';
import BestExperience from '../home/BestExperience';


const About = () => {
    return (
        <>
            <div className='' style={{ overflow: "hidden" }}>
                <section className="breadcrumb__area breadcrumb__bg" style={{ background: "url(./assets/img/bg/breadcrumb_bg.jpg)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="breadcrumb__content">
                                    <br />
                                    <br />
                                    <h2 className="title">About Us</h2>
                                    <nav aria-label="breadcrumb mt-lg-5">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">About</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb__shape">
                        <img src="assets/img/images/breadcrumb_shape01.png" alt />
                        <img src="assets/img/images/breadcrumb_shape02.png" alt className="rightToLeft" />
                        <img src="assets/img/images/breadcrumb_shape03.png" alt />
                        <img src="assets/img/images/breadcrumb_shape04.png" alt />
                        <img src="assets/img/images/breadcrumb_shape05.png" alt className="alltuchtopdown" />
                    </div>
                </section>


                <section className="about__area-four">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-9 col-sm-10">
                                <div className="about__img-wrap-four">
                                    <img src="assets/img/images/inner_about01.jpg" alt />
                                    <img src="assets/img/images/inner_about02.jpg" alt />
                                    <div className="about__award-box">
                                        <div className="icon">
                                            <i className="flaticon-trophy" />
                                        </div>
                                        <div className="content">
                                            <h2 className="title">5+</h2>
                                            <p>World Best Agency <br /> Award Got</p>
                                        </div>
                                    </div>
                                    <div className="shape">
                                        <img src="assets/img/images/inner_about_shape.jpg" alt className="alltuchtopdown" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about__content-four">
                                    <div className="section-title mb-30">
                                        <span className="sub-title">About Us</span>
                                        <h2 className="title">We Help Organizations To Make Ultimate Businesses Growth Success</h2>
                                    </div>
                                    <div className="about__content-inner-three">
                                        <div className="about__list-box">
                                            <ul className="list-wrap">
                                                <li><i className="flaticon-arrow-button" />Financial Services</li>
                                                <li><i className="flaticon-arrow-button" />Project Management</li>
                                                <li><i className="flaticon-arrow-button" />Real Estate Services</li>
                                            </ul>
                                        </div>
                                        <div className="about__list-img-two">
                                            <img src="assets/img/images/about_list_img02.png" alt />
                                        </div>
                                    </div>
                                    <p>Established in 2018 in Hyderabad. Surak Consultants Private Limited has partnered and supported leading companies across diverse industries including Banking and Finance services, Manufacturing, Logistics  in the strategy, planning, deployment and management of business critical infrastructure and software systems.</p>
                                    <Link to="/contact" className="btn">Contact With Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="testimonial__area-two">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="section-title white-title text-center mb-50">
                                    <span className="sub-title">Clients Testimonial</span>
                                    <h2 className="title">What Our Loving Clients Saying</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center gutter-24">
                            <div className="col-lg-4 col-md-6">
                                <div className="testimonial__item-two">
                                    <div className="testimonial__avatar">
                                        <img src="assets/img/images/profile.jpg" alt />
                                    </div>
                                    <div className="testimonial__rating">
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                    </div>
                                    <p> We at Surak Consultants Private Limited know you had many options to choose from, we thank you for choosing us.
                                    </p>
                                    <div className="icon">
                                        <img src="assets/img/icon/quote.svg" alt />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="testimonial__item-two">
                                    <div className="testimonial__avatar">
                                        <img src="assets/img/images/profile.jpg" alt />
                                    </div>
                                    <div className="testimonial__rating">
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                    </div>
                                    <p>Thank you for your business and your trust. It is our pleasure to work with you.</p>
                                    <div className="icon">
                                        <img src="assets/img/icon/quote.svg" alt />
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="testimonial__item-two">
                                    <div className="testimonial__avatar">
                                        <img src="assets/img/images/profile.jpg" alt />
                                    </div>
                                    <div className="testimonial__rating">
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                    </div>
                                    <p>Your support means the world to us! Thank for your business. Hope to work with you again in the future..</p>
                                    <div className="icon">
                                        <img src="assets/img/icon/quote.svg" alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial__shape-two">
                        <img src="assets/img/images/h2_testimonial_shape.png" alt data-aos="fade-up" data-aos-delay={400} />
                    </div>
                </section>

                <SuccessfullyCompleted />
                <BestExperience />
            </div>

        </>
    )
}

export default About