import { Link } from "react-router-dom";
import React from 'react'

const StockAudit = () => {
    return (
        <>
            <div className='' style={{ overflow: "hidden" }}>
                <section className="breadcrumb__area breadcrumb__bg" style={{ background: "url(./assets/img/bg/breadcrumb_bg.jpg)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="breadcrumb__content mt-lg-5">
                                <br/>
                                <br/>
                                    <h2 className="title">Stock Audit</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Stock Audit</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb__shape">
                        <img src="assets/img/images/breadcrumb_shape01.png" alt />
                        <img src="assets/img/images/breadcrumb_shape02.png" alt className="rightToLeft" />
                        <img src="assets/img/images/breadcrumb_shape03.png" alt />
                        <img src="assets/img/images/breadcrumb_shape04.png" alt />
                        <img src="assets/img/images/breadcrumb_shape05.png" alt className="alltuchtopdown" />
                    </div>
                </section>

                <section className="services__details-area">
                    <div className="container">
                        <div className="services__details-wrap">
                            <div className="row">
                                <div className="col-70 order-0 order-lg-2">
                                    <div className="services__details-top">
                                        <div className="row gutter-24 align-items-center">
                                            <div className="col-49">
                                                <div className="services__details-thumb services__details-thumb-two">
                                                    <img src="assets/img/services/services_details02.jpg" alt />
                                                </div>
                                            </div>
                                            <div className="col-51">
                                                <div className="services__details-top-content">
                                                    <h2 className="title">Stock Audit</h2>
                                                    <p>Stock audit refers to the comprehensive examination, verification, and valuation of a company's physical stock or inventory. It involves assessing the quantity, quality, condition, and valuation of the goods or materials held by a business.
                                                    </p>
                                                    <div className="about__list-box about__list-box-three">
                                                        <ul className="list-wrap">
                                                            <li><i className="flaticon-arrow-button" />Business Growth</li>
                                                            <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                            <li><i className="flaticon-arrow-button" />Register Your Business</li>
                                                            <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="services__details-content">
                                        <p>Counting and confirming the existence of each item in the inventory against the records maintained by the company.
                                            Checking the quality and condition of the stock to ensure it meets the required standards.</p>
                                        <p> Determining the value of the inventory, which might involve assessing the cost price, market value, or net realizable value of the goods.
                                            Examining the supporting documents such as invoices, purchase orders, sales records, and stock registers to ensure accuracy and proper maintenance of records.</p>
                                        <p>Identifying any variances or discrepancies between the physical count and the recorded figures, and investigating the reasons behind them.</p>
                                    </div>
                                </div>
                                <div className="col-30">
                                    <aside className="services__sidebar">
                                        <div className="sidebar__widget sidebar__widget-three">
                                            <div className="sidebar__cat-list-two sidebar__cat-list-three">
                                                <ul className="list-wrap">
                                                    <li><a to="services-details.html">Business Setup  <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Accounting % Book Keeping <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Statutory Compliances <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Internal Audits <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Financial Services <i className="flaticon-arrow-button" /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default StockAudit