import React from 'react'
import { Link } from "react-router-dom";


const ProjectReport = () => {
    return (
        <>
        <div className='' style={{overflow:"hidden"}}>

            <section className="breadcrumb__area breadcrumb__bg" style={{background: "url(./assets/img/bg/breadcrumb_bg.jpg)"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="breadcrumb__content mt-lg-5">
                            <br/>
                            <br/>
                                <h2 className="title">Project Report / Cma For Bank Loan</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Project Report / Cma For Bank Loan</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb__shape">
                    <img src="assets/img/images/breadcrumb_shape01.png" alt />
                    <img src="assets/img/images/breadcrumb_shape02.png" alt className="rightToLeft" />
                    <img src="assets/img/images/breadcrumb_shape03.png" alt />
                    <img src="assets/img/images/breadcrumb_shape04.png" alt />
                    <img src="assets/img/images/breadcrumb_shape05.png" alt className="alltuchtopdown" />
                </div>
            </section>

            <section className="services__details-area">
                <div className="container">
                    <div className="services__details-wrap">
                        <div className="row">
                            <div className="col-70 order-0 order-lg-2">
                                <div className="services__details-top">
                                    <div className="row gutter-24 align-items-center">
                                        <div className="col-49">
                                            <div className="services__details-thumb services__details-thumb-two">
                                                <img src="assets/img/services/services_details02.jpg" alt />
                                            </div>
                                        </div>
                                        <div className="col-51">
                                            <div className="services__details-top-content">
                                                <h2 className="title">Project Report and CMA</h2>
                                                <p>A Project Report or CMA (Credit Monitoring Arrangement) for a bank loan is a detailed document that outlines the various aspects of a proposed project or business for which you're seeking financial assistance from a bank or financial institution.
                                                </p>
                                                <div className="about__list-box about__list-box-three">
                                                    <ul className="list-wrap">
                                                        <li><i className="flaticon-arrow-button" />Business Growth</li>
                                                        <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                        <li><i className="flaticon-arrow-button" />Register Your Business</li>
                                                        <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="services__details-content">
                                    <p> A brief overview of the entire project, including its objectives, expected outcomes, and financial requirements.
                                        Detailed information about the business, its nature, products or services offered, market analysis, target audience, and unique selling propositions.</p>
                                    <p>Specifics about the project, such as its scope, timelines, location, technology involved, infrastructure requirements, etc.
                                        A comprehensive analysis of the industry, market trends, competition, potential customers, and marketing strategies planned.</p>
                                    <p>If applicable, technical specifications, production processes, machinery, technology, and any patents or copyrights involved.
                                        Detailed financial statements including profit and loss projections, cash flow forecasts, balance sheets, break-even analysis, and any assumptions made in these projections.</p>
                                </div>
                            </div>
                            <div className="col-30">
                                <aside className="services__sidebar">
                                    <div className="sidebar__widget sidebar__widget-three">
                                        <div className="sidebar__cat-list-two sidebar__cat-list-three">
                                            <ul className="list-wrap">
                                                <li><a to="services-details.html">Business Setup  <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Accounting % Book Keeping <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Statutory Compliances <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Internal Audits <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Financial Services <i className="flaticon-arrow-button" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        </>
    )
}

export default ProjectReport