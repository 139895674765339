import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom/dist";
import axios from "axios";
import moment from "moment/moment";

const BlogComponent = () => {
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState([]);
  const [temploding, settemploding] = useState(true);
  useEffect(() => {
    const fetchBlogs = async () => {
      const baseurl = "https://surakapi.bayfikar.in";
      const url = `${baseurl}/api/v1/blog/all`;
      const fetchdata = await axios.get(url);
      if (fetchdata.data.success) {
        const getBlogs = fetchdata.data.blogs;
        setBlogs(getBlogs);
        settemploding(false);
      }
    };
    fetchBlogs();
  }, []);

  console.log(blogs, "blogs");

  return (
    <>
      {temploding ? (
        <></>
      ) : (
        <>
          <section className="blog__area">
            <div className="container">
              <div className="blog__inner-wrap">
                <div className="row">
                  <div className="col-100">
                    <div className="blog-post-wrap">
                      <div className="row">
                        {blogs.map((individualBlog, index) => (
                          <div className="col-md-3" key={index}>
                            <div className="blog__post-two shine-animate-item">
                              <div className="blog__post-thumb-two">
                                <Link className="shine-animate">
                                  <img src={individualBlog.slider[0]} alt />
                                </Link>
                              </div>
                              <div className="blog__post-content-two">
                                <h2
                                  className="title"
                                  onClick={() =>
                                    navigate(`/blog/${individualBlog.slugUrl}`)
                                  }
                                >
                                  <Link>{individualBlog.blogTitle}</Link>
                                </h2>
                                <div className="blog-avatar">
                                  <div className="avatar-thumb">
                                    <img
                                      src="assets/img/blog/blog_avatar01.png"
                                      alt
                                    />
                                  </div>
                                  <div className="avatar-content">
                                    <p>
                                      By <Link>Surak Consultants Pv.Ltd</Link>
                                    </p>
                                    <p>
                                      <i className="fas fa-calendar-alt" />
                                      {moment(individualBlog.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default BlogComponent;
