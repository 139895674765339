import React from 'react'
import { Link } from "react-router-dom";


const DealAdvisory = () => {
    return (
        <>
            <div style={{ overflow: "hidden" }}>
                <section className="breadcrumb__area breadcrumb__bg" style={{ background: "url(./assets/img/bg/breadcrumb_bg.jpg)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="breadcrumb__content mt-lg-5">
                                    <br />
                                    <br />
                                    <h2 className="title">Deal Advisory</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Deal Advisory</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb__shape">
                        <img src="assets/img/images/breadcrumb_shape01.png" alt />
                        <img src="assets/img/images/breadcrumb_shape02.png" alt className="rightToLeft" />
                        <img src="assets/img/images/breadcrumb_shape03.png" alt />
                        <img src="assets/img/images/breadcrumb_shape04.png" alt />
                        <img src="assets/img/images/breadcrumb_shape05.png" alt className="alltuchtopdown" />
                    </div>
                </section>

                <section className="services__details-area">
                    <div className="container">
                        <div className="services__details-wrap">
                            <div className="row">
                                <div className="col-70 order-0 order-lg-2">
                                    <div className="services__details-top">
                                        <div className="row gutter-24 align-items-center">
                                            <div className="col-49">
                                                <div className="services__details-thumb services__details-thumb-two">
                                                    {/* <img src="assets/img/services/services_details02.jpg" alt /> */}
                                                    <img src="assets/img/img/2.JPEG" alt />
                                                </div>
                                            </div>
                                            <div className="col-51">
                                                <div className="services__details-top-content">
                                                    <h2 className="title">Deal Advisory</h2>
                                                    <p>Deal advisory refers to a range of services provided by professionals, typically in finance or consulting, to support companies and individuals in making strategic business decisions related to mergers, acquisitions, divestitures, and other transactions. </p>
                                                    <div className="about__list-box about__list-box-three">
                                                        <ul className="list-wrap">
                                                            <li><i className="flaticon-arrow-button" />Business Growth</li>
                                                            <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                            <li><i className="flaticon-arrow-button" />Register Your Business</li>
                                                            <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="services__details-content">
                                        <p>Advising on the buying, selling, merging, or restructuring of companies to maximize value and achieve strategic objectives.
                                            Providing due diligence, financial analysis, and risk assessment to evaluate the financial and operational aspects of a potential deal.</p>
                                        <p>Determining the value of businesses, assets, or securities to aid in negotiations or financial reporting.
                                            Assisting distressed companies in restructuring debt, operations, or assets to improve financial health or managing insolvency situations.</p>
                                        <p>Assisting companies in raising capital through debt or equity financing, including IPOs (Initial Public Offerings).
                                            Developing models and forecasts to assess the financial impact of potential deals or strategies.</p>
                                    </div>
                                </div>
                                <div className="col-30">
                                    <aside className="services__sidebar">
                                        <div className="sidebar__widget sidebar__widget-three">
                                            <div className="sidebar__cat-list-two sidebar__cat-list-three">
                                                <ul className="list-wrap">
                                                    <li><a to="services-details.html">Business Setup  <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Accounting % Book Keeping <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Statutory Compliances <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Internal Audits <i className="flaticon-arrow-button" /></a></li>
                                                    <li><a to="services-details.html">Financial Services <i className="flaticon-arrow-button" /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default DealAdvisory