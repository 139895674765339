import React from 'react'

const Testimonial = () => {
    return (
        <>

            <section className="testimonial__area-two">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title white-title text-center mb-50">
                                <span className="sub-title">Clients Testimonial</span>
                                <h2 className="title">What Our Loving Clients Saying</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center gutter-24">
                        <div className="col-lg-4 col-md-6">
                            <div className="testimonial__item-two">
                                <div className="testimonial__avatar">
                                    <img src="assets/img/images/profile.jpg" alt />
                                </div>
                                <div className="testimonial__rating">
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                </div>
                                <p> We at Surak Consultants Private Limited know you had many options to choose from, we thank you for choosing us.
                                </p>
                                <div className="icon">
                                    <img src="assets/img/icon/quote.svg" alt />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="testimonial__item-two">
                                <div className="testimonial__avatar">
                                    <img src="assets/img/images/profile.jpg" alt />
                                </div>
                                <div className="testimonial__rating">
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                </div>
                                <p>Thank you for your business and your trust. It is our pleasure to work with you.</p>
                                <div className="icon">
                                    <img src="assets/img/icon/quote.svg" alt />
                                </div><br/>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="testimonial__item-two">
                                <div className="testimonial__avatar">
                                    <img src="assets/img/images/profile.jpg" alt />
                                </div>
                                <div className="testimonial__rating">
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                </div>
                                <p>Your support means the world to us! Thank for your business. Hope to work with you again in the future..</p>
                                <div className="icon">
                                    <img src="assets/img/icon/quote.svg" alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="testimonial__shape-two">
                    <img src="assets/img/images/h2_testimonial_shape.png" alt data-aos="fade-up" data-aos-delay={400} />
                </div>
            </section>
        </>
    )
}

export default Testimonial