import React, { useState } from 'react'
import { Link } from "react-router-dom";


const Hadear = () => {
    const [toggle, setToggle] = useState(false);

    // const CloseClick = () => {
    //     setToggle(false);

    // };
    return (

        <div style={{ overflow: "hidden" }}>
            <button className="scroll__top scroll-to-target" data-target="html">
                <i className="fas fa-angle-up" />
            </button>

            <header className="transparent-header">
                <div className="tg-header__top">
                    <div className="container custom-container">
                        <div className="row">
                            <div className="col-lg-6">
                                <ul className="tg-header__top-info left-side list-wrap">
                                    <li><i className="flaticon-phone-call" /><a href="tel:9848181424">+91-9848181424, 9959037926</a></li>
                                    <li><i className="flaticon-pin" />H.No:2-52/A, Ground Floor, Chandanagar, Hyderabad</li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul className="tg-header__top-right list-wrap">

                                    <li><i className="flaticon-time" />Mon-Fri: 10:00am - 06:00pm</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="sticky-header" className="tg-header__area">
                    <div className="container custom-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="tgmenu__wrap">
                                    <nav className="tgmenu__nav">
                                        <div className="logo" onClick={() => setToggle(!toggle)}>
                                            <Link to="/"><img src="assets/img/img/pnglogo.png" alt="Logo" /></Link>
                                        </div>
                                        <div className="tgmenu__navbar-wrap tgmenu__main-menu d-none d-lg-flex">
                                            <ul className="navigation">
                                                <li className=" "><Link to="/">Home</Link>
                                                </li>
                                                <li className=""><Link to="/about">About Us</Link>

                                                </li>
                                                <li className=""><Link to="/Services">Services</Link>
                                                </li>
                                                <li><Link to="/contact">contacts</Link></li>
                                                <li><Link to="/blog">Blog</Link></li>
                                            </ul>
                                        </div>
                                        <div className="tgmenu__action d-none d-md-block">
                                            <ul className="list-wrap">

                                                <li className="header-btn"><Link to="/contact" className="btn">let’s Talk</Link></li>
                                            </ul>
                                        </div>
                                        {toggle === false ? (
                                            <>
                                                <div className="mobile-nav-toggler" onClick={() => setToggle(!toggle)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                                        <path d="M0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2Z" fill="currentcolor" />
                                                        <path d="M0 9C0 7.89543 0.895431 7 2 7C3.10457 7 4 7.89543 4 9C4 10.1046 3.10457 11 2 11C0.895431 11 0 10.1046 0 9Z" fill="currentcolor" />
                                                        <path d="M0 16C0 14.8954 0.895431 14 2 14C3.10457 14 4 14.8954 4 16C4 17.1046 3.10457 18 2 18C0.895431 18 0 17.1046 0 16Z" fill="currentcolor" />
                                                        <path d="M7 2C7 0.895431 7.89543 0 9 0C10.1046 0 11 0.895431 11 2C11 3.10457 10.1046 4 9 4C7.89543 4 7 3.10457 7 2Z" fill="currentcolor" />
                                                        <path d="M7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9Z" fill="currentcolor" />
                                                        <path d="M7 16C7 14.8954 7.89543 14 9 14C10.1046 14 11 14.8954 11 16C11 17.1046 10.1046 18 9 18C7.89543 18 7 17.1046 7 16Z" fill="currentcolor" />
                                                        <path d="M14 2C14 0.895431 14.8954 0 16 0C17.1046 0 18 0.895431 18 2C18 3.10457 17.1046 4 16 4C14.8954 4 14 3.10457 14 2Z" fill="currentcolor" />
                                                        <path d="M14 9C14 7.89543 14.8954 7 16 7C17.1046 7 18 7.89543 18 9C18 10.1046 17.1046 11 16 11C14.8954 11 14 10.1046 14 9Z" fill="currentcolor" />
                                                        <path d="M14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16C18 17.1046 17.1046 18 16 18C14.8954 18 14 17.1046 14 16Z" fill="currentcolor" />
                                                    </svg>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="close-btn" onClick={() => setToggle(!toggle)}>
                                                    <i className="fas fa-times" />
                                                </div>
                                            </>
                                        )}
                                    </nav>
                                </div>
                                {/* Mobile Menu  */}
                                {toggle === true ? (<>
                                    {/* <div className="tgmobile__menu">
                                        <nav className="tgmobile__menu-box">
                                            <div className="tgmobile__menu-outer">
                                                <ul className="navigation">
                                                    <li>
                                                        <a to="">Home</a>
                                                    </li> <li>
                                                        <a to="">About</a>
                                                    </li> <li>
                                                        <a to="">Services</a>
                                                    </li>
                                                    <li>
                                                        <a to="">contacts</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div> */}
                                    <div className='mobilemenu' >
                                        <div className='mobilemenu' >
                                            <div className="tgmobile__menu-outer">
                                                <ul className="navigation">
                                                    <li className='navigation' onClick={() => setToggle(!toggle)}>
                                                        <Link to="">Home</Link>
                                                    </li> <li onClick={() => setToggle(!toggle)}>
                                                        <Link to="/about">About</Link>
                                                    </li> <li onClick={() => setToggle(!toggle)}>
                                                        <Link to="/Services">Services</Link>
                                                    </li>
                                                    <li onClick={() => setToggle(!toggle)}>
                                                        <Link to="/contact">contacts</Link>
                                                    </li>
                                                    <li onClick={() => setToggle(!toggle)}>
                                                        <Link to="/blog">Blog</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                                    : <>
                                        <div className="tgmobile__menu-backdrop"> </div>
                                    </>}
                                {/* End Mobile Menu */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </div>
    )
}

export default Hadear