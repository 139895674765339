import React from 'react'
import { Link } from "react-router-dom";


const FinancialServices = () => {
    return (
        <>
<div className='' style={{overflow:"hidden"}}>
            <section className="breadcrumb__area breadcrumb__bg" style={{background: "url(./assets/img/bg/breadcrumb_bg.jpg)"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="breadcrumb__content mt-lg-5">
                            <br/>
                            <br/>
                                <h2 className="title">Financial Services</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Financial Services</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb__shape">
                    <img src="assets/img/images/breadcrumb_shape01.png" alt />
                    <img src="assets/img/images/breadcrumb_shape02.png" alt className="rightToLeft" />
                    <img src="assets/img/images/breadcrumb_shape03.png" alt />
                    <img src="assets/img/images/breadcrumb_shape04.png" alt />
                    <img src="assets/img/images/breadcrumb_shape05.png" alt className="alltuchtopdown" />
                </div>
            </section>

            <section className="services__details-area">
                <div className="container">
                    <div className="services__details-wrap">
                        <div className="row">
                            <div className="col-70 order-0 order-lg-2">
                                <div className="services__details-top">
                                    <div className="row gutter-24 align-items-center">
                                        <div className="col-49">
                                            <div className="services__details-thumb services__details-thumb-two">
                                                <img src="assets/img/services/services_details02.jpg" alt />
                                            </div>
                                        </div>
                                        <div className="col-51">
                                            <div className="services__details-top-content">
                                                <h2 className="title">Financial Services</h2>
                                                <p>Financial services encompass a broad range of economic services provided by financial institutions. These services are aimed at managing money, investments, and other financial transactions. </p>
                                                <div className="about__list-box about__list-box-three">
                                                    <ul className="list-wrap">
                                                        <li><i className="flaticon-arrow-button" />Business Growth</li>
                                                        <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                        <li><i className="flaticon-arrow-button" />Register Your Business</li>
                                                        <li><i className="flaticon-arrow-button" />100% Secure</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="services__details-content">
                                   <p> This includes services provided by banks such as savings accounts, checking accounts, loans (personal, business, mortgage), credit cards, and certificates of deposit.These involve managing investments, including buying and selling securities like stocks, bonds, mutual funds, and other investment products. This also includes investment advisory services, portfolio management, and wealth management.</p>
                                   <p>Companies offering insurance services provide various types of coverage, such as life insurance, health insurance, property insurance, and casualty insurance, to mitigate risks associated with life, health, or property.
 This involves services provided by financial planners or advisors to help individuals or businesses create strategies to reach their financial goals. This includes retirement planning, tax planning, estate planning, and overall financial advice.</p>
                                   <p>Financial institutions often provide services related to real estate, such as mortgage lending, property management, and real estate investment advice.
This involves facilitating transactions and payments, including electronic funds transfers, merchant services, and payment processing.</p>
                                </div>
                            </div>
                            <div className="col-30">
                                <aside className="services__sidebar">
                                    <div className="sidebar__widget sidebar__widget-three">
                                        <div className="sidebar__cat-list-two sidebar__cat-list-three">
                                            <ul className="list-wrap">
                                                <li><a to="services-details.html">Business Setup  <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Accounting % Book Keeping <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Statutory Compliances <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Internal Audits <i className="flaticon-arrow-button" /></a></li>
                                                <li><a to="services-details.html">Financial Services <i className="flaticon-arrow-button" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        </>
    )
}

export default FinancialServices