import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import axios from "axios";

const BlogDetails = () => {
  const [detailBlog, setDetailBlog] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userComment, setUserComment] = useState("");
  const param = useParams();

  useEffect(() => {
    const fetchBlogs = async () => {
      const baseurl = "https://surakapi.bayfikar.in";
      const url = `${baseurl}/api/v1/blog/all`;
      const fetchdata = await axios.get(url);
      if (fetchdata.data.success) {
        const getBlogs = fetchdata.data.blogs;
        const foundBlog = getBlogs.find(
          (individualBlog) => individualBlog.slugUrl === param.slugUrl
        );
        if (foundBlog) {
          setDetailBlog(foundBlog);
        }
      }
    };
    fetchBlogs();
  }, [param.slugUrl]);

  return (
    <>
      {detailBlog ? (
        <>
          <section className="blog__details-area">
            <div className="container">
              <div className="blog__inner-wrap">
                <div className="row">
                  <div className="col-100">
                    <div className="blog__details-wrap">
                      <div className="blog__details-thumb">
                        <img src={detailBlog.slider[0]} alt />
                      </div>
                      <div className="blog__details-content">
                        <h2 className="title">{detailBlog.blogTitle}</h2>
                        <div className="blog-post-meta">
                          <ul className="list-wrap">
                            {/* <li>
                              <a
                                href="blog.html"
                                className="blog__post-tag-two"
                              >
                                Business
                              </a>
                            </li> */}
                            <li>
                              <div className="blog-avatar">
                                <div className="avatar-thumb">
                                  <img src={detailBlog.avatar} alt />
                                </div>
                                <div className="avatar-content">
                                  <p>
                                    By{" "}
                                    <a href="blog-details.html">
                                      Surak Consultants Pv.Ltd
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <i className="fas fa-calendar-alt" />
                              {moment(detailBlog.createdAt).format("MMM Do YY")}
                            </li>
                            {/* <li>
                              <i className="far fa-comment" />
                              <a href="blog-details.html">05 Comments</a>
                            </li> */}
                          </ul>
                        </div>
                        {detailBlog.blogarray.map((content, conindex) => (
                          <p
                            key={conindex}
                            style={{ marginTop: "3vh", textAlign: "justify" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: content.blogContent,
                              }}
                              className="content-inner"
                            />
                          </p>
                        ))}

                        {/* <div className="blog__details-bottom">
                      <div className="row align-items-center">
                        <div className="col-md-7">
                          <div className="post-tags">
                            <h5 className="title">Tags:</h5>
                            <ul className="list-wrap">
                              <li>
                                <a href="#">Finance</a>
                              </li>
                              <li>
                                <a href="#">Data</a>
                              </li>
                              <li>
                                <a href="#">Agency</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="post-share">
                            <h5 className="title">Share:</h5>
                            <ul className="list-wrap">
                              <li>
                                <a href="#">
                                  <i className="fab fa-facebook-f" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fab fa-twitter" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fab fa-instagram" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fab fa-pinterest-p" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      </div>
                      {/* <div className="blog__avatar-wrap mb-60">
                    <div className="blog__avatar-img">
                      <a href="#">
                        <img
                          src="assets/img/blog/blog_avatar01.png"
                          alt="img"
                        />
                      </a>
                    </div>
                    <div className="blog__avatar-info">
                      <span className="designation">Author</span>
                      <h4 className="name">
                        <a href="#">Parker Willy</a>
                      </h4>
                      <p>
                        Finanappreciate your trust greatly Our clients choose
                        dentace ducts because kn ow we are the best area
                        Awaitingare really.
                      </p>
                    </div>
                  </div> */}
                      {/* <div className="comments-wrap">
                    <h3 className="comments-wrap-title">02 Comments</h3>
                    <div className="latest-comments">
                      <ul className="list-wrap">
                        <li>
                          <div className="comments-box">
                            <div className="comments-avatar">
                              <img
                                src="assets/img/blog/comment01.png"
                                alt="img"
                              />
                            </div>
                            <div className="comments-text">
                              <div className="avatar-name">
                                <h6 className="name">Jessica Rose</h6>
                                <span className="date">December 27, 2024</span>
                              </div>
                              <p>
                                Finanappreciate your trust greatly Our clients
                                choose dentace ducts because know we are the
                                best area Awaitingare really.
                              </p>
                              <a href="#" className="reply-btn">
                                Reply
                              </a>
                            </div>
                          </div>
                          <ul className="children">
                            <li>
                              <div className="comments-box">
                                <div className="comments-avatar">
                                  <img
                                    src="assets/img/blog/comment02.png"
                                    alt="img"
                                  />
                                </div>
                                <div className="comments-text">
                                  <div className="avatar-name">
                                    <h6 className="name">Parker Willy</h6>
                                    <span className="date">
                                      December 28, 2024
                                    </span>
                                  </div>
                                  <p>
                                    Finanappreciate your trust greatly Our
                                    clients choose dentace ducts because know we
                                    are the best area Awaitingare really.
                                  </p>
                                  <a href="#" className="reply-btn">
                                    Reply
                                  </a>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="comment-respond">
                    <h3 className="comment-reply-title">Post a comment</h3>
                    <form action="#" className="comment-form">
                      <p className="comment-notes">
                        Your email address will not be published. Required
                        fields are marked *
                      </p>
                      <div className="form-grp">
                        <textarea
                          name="comment"
                          placeholder="Comment"
                          defaultValue={""}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-grp">
                            <input type="text" placeholder="Name" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-grp">
                            <input type="email" placeholder="Email" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-grp">
                            <input type="url" placeholder="Website" />
                          </div>
                        </div>
                      </div>
                      <div className="form-grp checkbox-grp">
                        <input type="checkbox" id="checkbox" />
                        <label htmlFor="checkbox">
                          Save my name, email, and website in this browser for
                          the next time I comment.
                        </label>
                      </div>
                      <button type="submit" className="btn">
                        Submit Post
                      </button>
                    </form>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default BlogDetails;
